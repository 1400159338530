import React from 'react'
import { FaBaseball } from 'react-icons/fa6'
import CareerModal from './CareerModal'


function OpenPositions() {
  const openings = ["Intern Software Engineer - (Flutter)", "Associate Software Engineer - (Flutter)"]
  return (
    <div className='OpenPositionsMain'>
    <h1>Currently we have vacancies for following positions</h1>
      <div className='OpenPositionsSub'>
      {openings.map((open,index) => (
        <a href="/careers/openings" key={index} target='_blank'>
       <div className='flex gap-3 items-center'>
       <FaBaseball/>
       <h1>
          {open}
        </h1>
       </div>
       </a>
      ))}
      <div className='h-24'></div>
     <CareerModal/>
      </div>
    </div>
  )
}

export default OpenPositions
