import React from 'react'
import TopMenu from '../Mainpage/TopMenu'
import FooterMenu from '../Mainpage/FooterMenu'
import { FaCircle } from 'react-icons/fa';

function PositionsRequirements() {

  const requirements = [
    {
      title:"Intern Software Engineer - (Flutter)", 
      description:[

      "Deep knowledge in any Programming language",
      "knowledge in Dart / Flutter will be an added advantage",
      "Basic understanding of web & mobile app development",
      "Good team player and have passion & willingness to adopt new technologies",
      "Good understanding of Git & Gitgub",

      ], 
      tasks:[
        "Developing UIs as per Figma designs",
        "Supporting with the team members",
        "Web & Mobile application maintenance",
        "Make reports & reviews",

      ],},
      {
        title:"Associate Software Engineer - (Flutter)", 
        description:[
        "Previous experience with web & mobile app development",
        "Experience with Dart / Flutter will be an added advantage",
        "Adequate knowledge in web & mobile app development",
        "Good logical thinking and can work independently or with minimum guidance"
            
        ], 
        tasks:[
        "Developing UIs as per Figma designs",
        "Integrate with Google products such as Firebase",
        "Web & Mobile application development & maintenance",
        "Database management",
      ],
    }
  ];


  return (
    <div className='flex flex-col gap-5'>
    <TopMenu/>
    <div className='h-9'></div>
      <div className=' flex justify-center'>
      <div className='w-[150vh] flex flex-col gap-5 items-center justify-center'>
      {requirements.map((requirement,index) => (
        <div className='w-3/4 flex flex-col items-center justify-center gap-3' key={index}>
        <div className='w-full'><h1 className='font-bold text-4xl'>{requirement.title}</h1></div>
        
        <div className='w-full'><h1 className='font-semibold text-xl'>Duties & Responsibilities</h1></div>
        <div className='w-full flex flex-col gap-2 justify-center'>
        
        {requirement.tasks.map((task,index)=>(
          <div className='flex gap-2' key={index}>
          <FaCircle className='font-normal text-xs'/>
          <h1 className='font-normal'>{task}</h1>
          </div>
        ))}
        </div>
        <div className='w-full'><h1 className='font-semibold text-xl'>Requirements</h1></div>
        <div className='w-full flex flex-col gap-2 justify-center'>{requirement.description.map((description,index)=>(
          <div className='flex gap-2' key={index}>
          <FaCircle className='font-normal text-xs'/>
          <h1 className='font-normal'> {description}</h1>
          </div>
        ))}
        </div>
        </div>
      ))}
      </div>
      </div>
      <FooterMenu/>
    </div>
  )
}

export default PositionsRequirements
